import React, { useEffect, useState } from "react";
import styles from "./PreviewVideo.module.css";
import { useData } from './../../hooks/useData';
import { getUserVideoData } from './../../api/crud';

export default function PreviewVideo() {
  // eslint-disable-next-line no-unused-vars
  const [data, dispatchData] = useData();
  const [userData, setUserData] = useState({})
  const [loadingVideo, setLoadingVideo] = useState(false);

  useEffect(() => {
    setLoadingVideo(true);
    let timer = setTimeout(async function tick() {
      const [userVideoData, userVideoDataError] = await getUserVideoData(data.memorial.memorialId, data.memorial.userId)

      if (!userVideoDataError) {
        if (!userVideoData.preparedVideo) {
          timer = setTimeout(tick, 5000);
        } else {
          setUserData(userVideoData)
          // dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
          setLoadingVideo(false);
          clearTimeout(timer);
        }
      }
    }, 0);
  }, [])


  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Your video</h2>
          <p className="section-description">
            Here's the video you sent
          </p>

          {loadingVideo && <div className={styles["video-loading"]}>
            <svg className={styles["spinner"]} viewBox="0 0 50 50">
              <circle className={styles["path"]} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
            <p className="section-description">Loading video</p>
          </div>}
        
          {!loadingVideo && <div className={styles["video-preview"]}>
            <video playsInline className={styles["video"]} src={userData.preparedVideo} controls controlsList="nodownload"></video>
          </div>}
        </div>
      </section>
    </>
  );
}
