import logo from '../../assets/logo.png'
import visa from '../../assets/visa.webp'
import mastercard from '../../assets/mastercard.webp'
import facebook from '../../assets/facebook-icon.png'
import twitter from '../../assets/twitter-icon.png'
import instagram from '../../assets/instagram-icon.png'

import styles from './Footer.module.css';

export default function Footer() {

  return (
    <>
      <footer className={styles['footer']}>
        <div className='container'>
          <div className={styles['footer__top']}>
            <div className={styles['footer__cards']}>
              <p  className={styles['footer__cards-title']}>We accept:</p>
              <ul className={styles['footer__cards-list']}>
                <li>
                  <img src={visa} alt="visa" />
                </li>
                <li>
                  <img src={mastercard} alt="mastercard" />
                </li>
              </ul>
            </div>
            <ul className={styles['footer__funeral']}>
              <li>
                <a href="https://beforever.online/" target="_blank" rel="noreferrer noopener">Funeral organization</a>
              </li>
              <li>
                <a href="https://beforever.online/" target="_blank" rel="noreferrer noopener">Partnership</a>
              </li>
              <li>
                <a href="https://beforever.online/" target="_blank" rel="noreferrer noopener">funeral planning</a>
              </li>
              <li>
                <a href="https://beforever.online/memorials" target="_blank" rel="noreferrer noopener">memorials</a>
              </li>
              <li>
                <a href="https://beforever.online/terms-and-conditions" target="_blank" rel="noreferrer noopener">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="https://beforever.online/contact-us" target="_blank" rel="noreferrer noopener">Contact us</a>
              </li>
              <li>
                <a href="https://beforever.online/faqs" target="_blank" rel="noreferrer noopener">FAQ</a>
              </li>
              <li>
                <a href="https://beforever.online/about" target="_blank" rel="noreferrer noopener">About us</a>
              </li>
            </ul>
            <div className={styles['footer__address']}>
              <p  className={styles['footer-title']}>REGISTERED OFFICE ADDRESS:</p>
              <p className={styles['address-text']}>New York</p>
            </div>
            <div className={styles['footer__follow']}>
              <p className={styles['footer-title']}>Folow us</p>
              <ul className={styles['footer__follow-list']}>
                <li>
                  <a href="https://www.facebook.com/BeForever-106286948031755/" target="_blank" rel="noreferrer noopener">
                    <img src={facebook} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank" rel="noreferrer noopener">
                    <img src={twitter} alt="twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank" rel="noreferrer noopener">
                    <img src={instagram} alt="instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles['footer__bottom']}>
            <div className={styles['footer-logo']}>
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </div>
            <div className={styles['footer-copy']}>
              © 2021 Copyright
              <span>© All right reserved</span>
            </div>
          </div>
        </div>        
      </footer>
    </>
  );
}




