export function parseGetParams() {
    const pl = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query = window.location.search.substring(1);

    const urlParams = {};
    let match;
    while (match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);
    return urlParams
}


export function parseGetParamsWithB64() {
    const getB64 = parseGetParams()
    const getB64keys = Object.keys(getB64)
    if (getB64keys.length === 0){
        return null
    }
    const urlParams = {}
    getB64keys.forEach(key => {
        try {
            urlParams[key] = JSON.parse(window.atob(getB64[key]))
        } catch (error) {
            urlParams[key] = getB64[key]
        }
    })
    return urlParams
}
