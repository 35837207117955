import React, { useState, useEffect } from "react";
import styles from "./PreviewFilmSection.module.css";
import { useData } from "./../../hooks/useData";
import { BASE_URL, setCompile, getMirror } from "../../api/crud";

const timestamp = new Date().getTime();

export default function PreviewFilmSection() {
  const [data, dispatch] = useData();
  const [applyDisabledReq, setApplyDisabledReq] = useState(false);
  const [applyDisabledCompleted, setApplyDisabledCompleted] = useState(false);
  const [applyDisabledEdit, setApplyDisabledEdit] = useState(false);
  const [applyDisabledDuration, setApplyDisabledDuration] = useState(false);

  useEffect(() => {
    data?.mirror.state === "Pending" && !applyDisabledReq && checkMirrorState();
  }, [data]);

  async function sendToCompile() {
    setApplyDisabledReq(true);
    const [, compileReqError] = await setCompile(data.mirror.mirrorId);
    if (!compileReqError) {
      checkMirrorState();
    } else {
      setApplyDisabledReq(false);
    }
  }

  function checkMirrorState() {
    setApplyDisabledReq(true);
    let timer = setTimeout(async function tick() {
      const [mirrorData, mirrorDataError] = await getMirror(data.mirror.mirrorId);
      if (!mirrorDataError) {
        if (mirrorData.state === "Pending") {
          timer = setTimeout(tick, 30000);
        } else {
          if (mirrorData.state === "Completed") {
            setApplyDisabledCompleted(true)
            dispatch({ type: "EDIT_FILM", payload: false });
            dispatch({ type: "EDIT_FILM_CHANGES", payload: false });
          }
          setApplyDisabledReq(false);
          clearTimeout(timer);
          dispatch({ type: "SET_MIRROR", payload: mirrorData });
        }
      }
    }, 30000);
  }

  useEffect(() => {
    if (data?.mirror.film) {
      setApplyDisabledCompleted(true);
    } 
    if (data?.durationFilm > data?.durationFilmLimit) {
      setApplyDisabledDuration(true);
    } else {
      setApplyDisabledDuration(false);
    }

    if (data?.editingFilm) {
      if (data?.editingFilmChanges) {
        setApplyDisabledEdit(false);
        setApplyDisabledCompleted(false)
      } else {
        setApplyDisabledEdit(true);
      }
    }
  }, [data]);

  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Preview</h2>
          <div className={styles["section-content"]}>
            <p className="section-description">
              This film of memory is almost ready. Click the Apply to send it to the server for pre-compilation. Or go
              back to previous steps to make changes.
            </p>
            <button
              onClick={sendToCompile}
              className="main-btn"
              disabled={applyDisabledReq || applyDisabledDuration || applyDisabledEdit || applyDisabledCompleted}
            >
              Apply
            </button>
          </div>
          <div className={styles["preview"]}>
            {(data?.mirror.state === "Pending" || applyDisabledReq) && (
              <div  className={styles["preview__waiting"]}>
                <p className="section-description">Thank you</p>
                <p className="section-description">Your video will be available for viewing after processing on the server. It will take some time.</p>
                <svg className={styles["spinner"]} viewBox="0 0 50 50">
                  <circle className={styles["path"]} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
                <p className="section-description">If your video does not appear, please contact our support.</p>
              </div>
            )}
            {data?.mirror.state === "Fail" && !applyDisabledReq && <p className="section-description">Fail compile film</p>}
            {data?.mirror.state === "Completed" && !applyDisabledReq && <PreviewVideo />}
          </div>
        </div>
      </section>
    </>
  );
}

function PreviewVideo() {
  const [data, dispatch] = useData();

  function editFilm() {
    dispatch({ type: "EDIT_FILM", payload: true });
    window.scrollTo(0, 0);
  }

  return (
    <>
      <p className="section-description">Done. You can watch it here to publish or edit</p>

      <div className={styles["video-preview"]}>
        <video
          controlsList="nodownload"
          playsInline
          className={styles["video"]}
          src={`${data.mirror.film}?t=${timestamp}`}
          controls
        ></video>
      </div>

      <div className={styles["video-controls"]}>
        <button onClick={editFilm} className={`${styles["video-controls__btn"]} close-btn`}>
          Edit film
        </button>
        <button className={`${styles["video-controls__btn"]} main-btn`}>Publish film</button>
      </div>
    </>
  );
}
