import React, { useState, useEffect } from "react";
import styles from "./SelectVideo.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getVideoList, setScenario } from "../../api/crud";
import { BASE_URL_SHORT } from "./../../api/crud";
import { useData } from "./../../hooks/useData";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const customModalStyles = {
  modal: {
    padding: "unset",
    overflowY: "visible",
    backgroundColor: "#333333"
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)"
  },
  closeButton: {
    top: "-30px",
    right: "0"
  },
  closeIcon: {
    stroke: 'white',
    fill: 'transparent',
    strokeLinecap: 'round',
    strokeWidth: 3
  }
}
const closeIcon = (
  <svg viewbox="0 0 40 40">
    <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
  </svg>
);

const timestamp = new Date().getTime();

export default function SelectVideos() {
  const [data] = useData();
  const disabled = data.mirror.film && !data.editingFilm ? styles['disabled-selection'] : ''
  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Select videos</h2>
          <p className="section-description">Just drag and drop from the top list to the bottom list</p>
          <div className={disabled}>
            <VideoList />
          </div>
        </div>
      </section>
    </>
  );
}

function VideoList() {
  const [data, dispatchData] = useData();
  const [isOpenVideoModal, setOpenVideoModal] = useState(false);
  const [modalVideoUrl, setModalVideoUrl] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [fullVideoList, setFullVideoList] = useState([]);
  const [durationFilm, setDurationFilm] = useState(0);
  const [durationFilmWarning, setDurationFilmWarning] = useState("");
  const [videoLists, setVideoLists] = useState({
    initialVideoList: [],
    finalVideoList: [],
    allVideosIds: [],
  });

  function openModalVideo(url) {
    setModalVideoUrl(url);
    setOpenVideoModal(true);

  }

  

  useEffect(() => {
    let duration = 0;

    videoLists.finalVideoList.forEach((video) => {
      duration += video.duration;
    });

    setDurationFilm(duration);
    dispatchData({ type: "SET_DURATION_FILM", payload: duration });

    if (data.editingFilm) {
      dispatchData({ type: "EDIT_FILM_CHANGES", payload: true });
    }

    if (duration > data.durationFilmLimit) {
      setDurationFilmWarning("warning");
    }
    if (duration < data.durationFilmLimit) {
      setDurationFilmWarning("");
    }
  }, [videoLists.finalVideoList]);

  useEffect(() => {
    const allVideosIds = fullVideoList.map((video) => video.userId);
    if (JSON.stringify(allVideosIds) !== JSON.stringify(videoLists.allVideosIds)) {
      setVideoLists({ ...videoLists, allVideosIds });
      const scenario = data.mirror.scenario.videos;

      if (scenario.length > 0) {
        const filteredInitialVideoList = fullVideoList.filter(
          (initialVideo) => !scenario.includes(initialVideo.userId)
        );
        const filteredFinalVideoList = scenario
          .map((id) => fullVideoList.find((initialVideo) => initialVideo.userId === id))
          .filter((video) => !!video);
        setVideoLists({ initialVideoList: filteredInitialVideoList, finalVideoList: filteredFinalVideoList });
      } else {
        setVideoLists({ initialVideoList: fullVideoList, finalVideoList: [] });
      }
      if (!firstLoad) {
        setFirstLoad(true);
      }
    }
  }, [fullVideoList]);

  useEffect(() => {
    let timer = setTimeout(async function tick() {
      const [videoList, videoListError] = await getVideoList(data.mirror.mirrorId);
      if (!videoListError) {
        setFullVideoList(videoList);
      }

      setTimeout(tick, 120 * 1000);
    }, 0);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstLoad) {
      (async function () {
        const scenarioVideos = videoLists.finalVideoList.map((finalVideo) => finalVideo.userId);
        const newScenario = {
          ...data.mirror.scenario,
          videos: scenarioVideos,
        };
        const equalsScenarios = JSON.stringify(data.mirror.scenario) === JSON.stringify(newScenario);
        if (!equalsScenarios) {
          const [, setScenarioError] = await setScenario(data.mirror.mirrorId, newScenario);
          if (!setScenarioError) {
            dispatchData({ type: "SET_SCENARIO", payload: newScenario });
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLists.finalVideoList]);

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const move = (sourceList, destinationList, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(sourceList);
    const destClone = Array.from(destinationList);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  function onDragEnd({ source, destination }) {
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(videoLists[source.droppableId], source.index, destination.index);
      setVideoLists({ ...videoLists, [source.droppableId]: items });
    } else {
      const result = move(videoLists[source.droppableId], videoLists[destination.droppableId], source, destination);
      setVideoLists(result);
    }
  }

  const getItemStyle = (draggableStyle, poster) => ({
    backgroundImage: `url( ${BASE_URL_SHORT}${poster}?t=${timestamp} )`,
    ...draggableStyle,
  });

  return (
    <>
      <Modal
        open={isOpenVideoModal}
        onClose={() => setOpenVideoModal(false)}
        styles={customModalStyles}
        center
        // closeIcon={closeIcon}
      >
        <video 
          className={styles["modal__video"]} 
          src={modalVideoUrl} 
          controls 
          controlsList="nodownload"
          playsInline
        ></video>
      </Modal>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="initialVideoList" direction="horizontal">
          {(provided) => (
            <div className={styles["videoList"]} ref={provided.innerRef}>
              {videoLists.initialVideoList.map((video, index) => {
                return (
                  <Draggable key={video.userId.toString()} draggableId={video.userId.toString()} index={index}>
                    {(provided) => {
                      return (
                        <div
                          className={styles["video"]}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(provided.draggableProps.style, video.screen)}
                        >
                          <div  className={styles["video-info"]}>
                            <div>
                              <h3 className={styles["video-info__user-name"]}>
                                {video.userName}
                              </h3>
                              <p className={styles["video-info__user-relation"]}>
                                {video.relation}
                              </p>
                            </div>
                            <p className={styles["video-info__video-duration"]}>
                              {(video.duration / 60).toFixed(1)} min
                            </p>
                          </div>

                          <div className={styles["play"]}>
                            <button
                              onClick={() => openModalVideo(video.preparedVideo)}
                              className={styles["play-btn"]}
                            ></button>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="finalVideoList" direction="horizontal">
          {(provided) => (
            <div className={styles["videoList"]} ref={provided.innerRef}>
              {videoLists.finalVideoList.map((video, index) => {
                return (
                  <Draggable key={video.userId.toString()} draggableId={video.userId.toString()} index={index}>
                    {(provided) => {
                      return (
                        <div
                          className={styles["video"]}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(provided.draggableProps.style, video.screen)}
                        >
                          <div  className={styles["video-info"]}>
                            <div>
                              <h3 className={styles["video-info__user-name"]}>
                                {video.userName}
                              </h3>
                              <p className={styles["video-info__user-relation"]}>
                                {video.relation}
                              </p>
                            </div>
                            <p className={styles["video-info__video-duration"]}>
                              {(video.duration / 60).toFixed(1)} min
                            </p>
                          </div>
                          
                          <div className={styles["play"]}>
                            <button
                              onClick={() => openModalVideo(video.preparedVideo)}
                              className={styles["play-btn"]}
                            ></button>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={styles["duration"]}>
        <p className={styles["duration__info"]}>The duration of the film should not exceed 15 minutes</p>
        <p className={`${styles["duration__now"]} ${styles[durationFilmWarning]}`}>
          Duration film now: {(durationFilm / 60).toFixed(1)} minutes
        </p>
      </div>
    </>
  );
}
