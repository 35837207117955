import { useContext, useReducer, createContext } from "react";


const initialState = {
    show: false,
    showButtons: true,
    status: null,
    error: null,
    mediaRecorder: null
}

const RecordContext = createContext()

export const useRecord = () => useContext(RecordContext)

const RecordProvider = ({ children }) => {
    const [record, dispatchRecord] = useReducer(recordReducer, initialState)

    function recordReducer(state, action) {
        switch (action.type) {
            case 'SET_SHOW':
                return { ...state, show: action.payload }
            case 'SET_SHOW_BUTTONS':
                return { ...state, showButtons: action.payload }
            case 'SET_STATUS':
                return { ...state, status: action.payload }
            case 'SET_ERROR':
                return { ...state, error: action.payload }
            case 'SET_MEDIA_RECORDER':
                return { ...state, mediaRecorder: action.payload }
            default:
                throw new Error('Incorrect action type!')
        }
    }

    return <RecordContext.Provider value={[record, dispatchRecord]}>{children}</RecordContext.Provider>
}

export default RecordProvider