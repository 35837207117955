import { useContext, useReducer, createContext } from "react";
const initialState = {
    currentStep: 1
}
const Context = createContext()
export const useSteps = () => useContext(Context)
const StepsProvider = ({ children }) => {
    const [data, dispatch] = useReducer(reducer, initialState)
    function reducer(state, action) {
        switch (action.type) {
            case 'SET_STEP':
                return {...state, currentStep: action.payload}
            default:
                throw new Error('Wrong action type!')
        }
    }
    return <Context.Provider value={[data, dispatch]}>{children}</Context.Provider>
}
export default StepsProvider