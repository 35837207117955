// import { useReactMediaRecorder } from "react-media-recorder";
import React, { useEffect, useRef, useState } from "react";
import styles from "./RecordVideo.module.css";

import { useReactMediaRecorder } from "../../hooks/useReactMediaRecorder";
import { useRecord } from "./../../hooks/useRecord";
import StepsProvider from "../../hooks/useSteps";
import { useSteps } from "./../../hooks/useSteps";
import { setVideo, getUserVideoData } from "../../api/crud";
import { useData } from "./../../hooks/useData";

import img from '../../assets/silhouette.png'

const initialOptions = {
  limit: 180,
};
const timerFormatter = new Intl.DateTimeFormat(undefined, {
  minute: "2-digit",
  second: "2-digit",
});

export default function RecordVideo({ limit }) {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [record, dispatch] = useRecord();
  const [data, dispatchData] = useData();
  const { status, error, startRecording, stopRecording, mediaBlobUrl, previewStream, originalStream } =
    useReactMediaRecorder({
      video: true,
    });
  const [recordBtnText, setRecordBtnText] = useState("");
  const [videoBlob, setVideoBlob] = useState(null);

  useEffect(() => {
    dispatch({ type: "SET_STATUS", payload: status });
  }, [status]);

  useEffect(() => {
    dispatch({ type: "SET_ERROR", payload: error });
  }, [error]);

  function cancelRecording() {
    originalStream?.getTracks().forEach((track) => track.stop());
    dispatch({ type: "SET_SHOW_BUTTONS", payload: true });
    dispatch({ type: "SET_SHOW", payload: false });
  }

  useEffect(() => {
    (async function () {
      const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
      setVideoBlob(blob);
    })();
  }, [mediaBlobUrl]);

  useEffect(() => {
    switch (status) {
      case "idle":
        setRecordBtnText("Start recording");
        break;
      case "recording":
        setRecordBtnText("Stop recording");
        break;
      case "stopped":
        setRecordBtnText("Retry recording");
        break;
      default:
        break;
    }
  }, [status]);

  function recordToggle() {
    switch (status) {
      case "idle":
        startRecording();
        break;
      case "stopped":
        startRecording();
        break;
      case "recording":
        stopRecording();
        break;
      default:
        break;
    }
  }

  async function uploadVideo() {
    setDisabledSubmitBtn(true);
    const formData = new FormData();
    const videoFile = new File([videoBlob], `${data.memorial.userId}${data.memorial.memorialId}.mp4`, {
      type: videoBlob.type,
    });

    formData.append("video", videoFile);

    const [uploadedVideo, uploadedVideoError] = await setVideo(
      data.memorial.memorialId,
      data.memorial.userId,
      formData
    );
    
    if (!uploadedVideoError) {
      const [userVideoData, userVideoDataError] = await getUserVideoData(data.memorial.memorialId, data.memorial.userId)

      if (!userVideoDataError) {
        dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
      }
      alert("Success upload video! It will be available for viewing soon.");
    } else {
      alert("Upload Video Error");
    }
    setDisabledSubmitBtn(false);
  }

  return (
    <>
      {!error && status && status !== "acquiring_media" && (
        <Record
          {...{
            recordToggle,
            cancelRecording,
            uploadVideo,
            limit,
            status,
            stopRecording,
            mediaBlobUrl,
            previewStream,
            recordBtnText,
            disabledSubmitBtn,
          }}
        ></Record>
      )}
    </>
  );
}

function Record({
  recordToggle,
  cancelRecording,
  uploadVideo,
  limit,
  status,
  stopRecording,
  mediaBlobUrl,
  previewStream,
  recordBtnText,
  disabledSubmitBtn,
}) {
  return (
    <>
      <StepsProvider>
        <div className='grid'>
          <div className={`${styles['grid-item']} grid-item `}>
            <TextSteps></TextSteps>
          </div>

          <div className={`${styles['grid-item']} grid-item `}>
            <div className={styles["video-preview"]}>
              {status !== "stopped" && <VideoPreview stream={previewStream} />}
              {status === "stopped" && <VideoPlayer src={mediaBlobUrl} />}
            </div>

            <div className={styles["timer-wrap"]}>
              {status === "recording" && <Timer limit={limit || initialOptions.limit} stopRec={stopRecording} />}
            </div>
          </div>

          <div className={`${styles['grid-item']} grid-item`}>
            <div className={styles["video-controls"]}>
              <button className={`${styles["video-controls__btn"]} main-btn`} onClick={recordToggle}>
                {recordBtnText}
              </button>
              <button className={`${styles["video-controls__btn"]} close-btn`} onClick={cancelRecording}>
                Cancel
              </button>
              {status === "stopped" && (
                <button
                  className={`${styles["video-controls__btn"]} main-btn`}
                  onClick={uploadVideo}
                  disabled={disabledSubmitBtn}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </StepsProvider>
    </>
  );
}

const Timer = ({ limit, stopRec }) => {
  const [time, setTime] = useState(0);
  const [steps, dispatchSteps] = useSteps();
  const percent = (time / limit) * 100;

  useEffect(() => {
    if (time > limit / 3 && time < 2 * (limit / 3) && steps.currentStep !== 2) {
      dispatchSteps({ type: "SET_STEP", payload: 2 });
    } else if (time > 2 * (limit / 3) && steps.currentStep !== 3) {
      dispatchSteps({ type: "SET_STEP", payload: 3 });
    }
  }, [time]);

  useEffect(() => {
    let seconds = 0;
    let timerId = setTimeout(function step() {
      if (seconds < limit) {
        seconds++;
        setTime((prev) => prev + 1);
        timerId = setTimeout(step, 1000);
      } else {
        clearTimeout(timerId);
        stopRec();
      }
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      <div className={styles["timer"]}>
        <span className={styles["time"]}>{timerFormatter.format((limit - time) * 1000)}</span>
        <span className={styles["percent"]}>{percent.toFixed(0)}%</span>
      </div>
      <div className={styles["progressbar"]} style={{ width: `${(time / limit) * 100}%` }}></div>
    </>
  );
};

function VideoPlayer({ src }) {
  return (
    <>
      <video playsInline controlsList="nodownload" className={styles["video"]} src={src} controls />
    </>
  );
}

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return <></>;
  }

  return  (
    <div className={styles["relative"]}>
        <video playsInline controlsList="nodownload" className={styles["video"]} ref={videoRef} autoPlay />
        <img className={styles["overlay"]} src={img} alt="sil" />
    </div>
  
  )
};

function TextSteps() {
  const [steps] = useSteps();
  return (
    <ul className={styles["steps"]}>
      <li className={`${styles["step"]} ${steps.currentStep === 1 ? styles["current-step"] : ""}`}>
        Please tell about your relations with the deseased
      </li>
      <li className={`${styles["step"]} ${steps.currentStep === 2 ? styles["current-step"] : ""}`}>
        Tell us about the best highlights you remember from your relationship
      </li>
      <li className={`${styles["step"]} ${steps.currentStep === 3 ? styles["current-step"] : ""}`}>
        Express condolences to family and friends
      </li>
    </ul>
  );
}
