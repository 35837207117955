import React, { useState, useEffect } from "react";
import styles from "./SelectPhoto.module.css";
import { useData } from "./../../hooks/useData";
import { BASE_URL, setImage } from "../../api/crud";

export default function SelectPhoto() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [data] = useData();

  const disabled = data.mirror.film && !data.editingFilm ? styles['disabled-selection'] : ''

  useEffect(() => {
    let objectUrl = null;
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }

    const file = e.target.files[0];
    const fileNameArray = e.target.files[0].name.split(".");
    const fileExt = fileNameArray[fileNameArray.length - 1];
    const fileForUpload = new File([file], `${data.mirror.mirrorId}.${fileExt}`, { type: file.type });
    const formData = new FormData();
    formData.append("image", fileForUpload);
    const [, uploadImageError] = await setImage(data.mirror.mirrorId, formData);
    if (!uploadImageError) {
      setSelectedFile(file);
    }
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Select Photo for the film</h2>
          <div className={disabled}>
            <div className="grid">
              <div className={`${styles["grid-item"]} grid-item`}>
                <div className={styles["default-bg-preview"]}>
                  <img className={styles["uploaded-photo"]} src={preview || `${BASE_URL}mirror/image/${data.mirror.mirrorId}`} alt="Preview" />
                </div>
              </div>

              <div className={`${styles["grid-item"]} grid-item`}>
                <p className="section-description">
                  This photo will be shown at the end of the movie. Now we use the main photo from the memorial, but you
                  can choose another
                </p>

                <label className={`${styles["upload-photo-btn"]} main-btn`}>
                  Upload photo
                  <input onChange={onSelectFile} id="file-upload" type="file" accept="image/*" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
