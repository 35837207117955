import { useContext, useReducer, createContext, useEffect } from "react";
import { getMirror, createMirror, getUserVideoData, setUserVideoData, setImage, api } from "../api/crud";
import { parseGetParamsWithB64 } from './../utils/parseGetParams';


const initialState = {
    mirror: null,
    memorial: null,
    firstLoad: false,
    render: 0,
    userVideoData: null,
    editingFilm: false,
    editingFilmChanges: false,
    durationFilm: 0,
    durationFilmLimit: 900
}

function dataReducer(state, action) {
    switch (action.type) {
        case "INCREMENT_RENDER":
            return { ...state, render: state.render + 1 }
        case "SET_MEMORIAL":
            return { ...state, memorial: action.payload }
        case "SET_MIRROR":
            return { ...state, mirror: action.payload }
        case "SET_SCENARIO": {
            const mirrorWithNewScenario = { ...state.mirror, scenario: action.payload }
            return { ...state, mirror: mirrorWithNewScenario }
        }
        case 'SET_FIRST_LOAD':
            return { ...state, firstLoad: action.payload }
        case 'SET_USER_VIDEO_DATA':
            return { ...state, userVideoData: action.payload }
        case 'EDIT_FILM':
            return { ...state, editingFilm: action.payload }
        case 'EDIT_FILM_CHANGES':
            return { ...state, editingFilmChanges: action.payload }
        case 'SET_DURATION_FILM':
            return { ...state, durationFilm: action.payload }
        default:
            throw new Error('Incorrect action type!')
    }
}

const DataContext = createContext()

export const useData = () => useContext(DataContext)

const DataProvider = ({ children }) => {
    const [data, dispatchData] = useReducer(dataReducer, initialState)

    useEffect(() => {
        (async function () {
            const params = parseGetParamsWithB64()
            console.log(params);

            if (params?.memorial?.memorialId) {
                const memorialData = params.memorial
                dispatchData({ type: "SET_MEMORIAL", payload: memorialData });
                // memorialData.memorialId = 3
                const [mirrorData, mirrorDataError] = await getMirror(memorialData.memorialId)
                console.log('Exists mirrorData!', mirrorData);
                if (!mirrorDataError) {
                    dispatchData({ type: "SET_MIRROR", payload: mirrorData });
                    checkUserVideoData(memorialData)
                }
                if (mirrorDataError) {
                    if (memorialData.userRole === 'maintainer') {
                        const newMirrorData = {
                            mirrorId: memorialData.memorialId,
                            creatorId: memorialData.userId,
                            creatorName: memorialData.userName,
                            personName: memorialData.personName,
                            dateBirth: memorialData.dateBirth,
                            dateDeath: memorialData.dateDeath,
                        }
                        // newMirrorData.mirrorId = 3
                        const [mirrorData, mirrorDataError] = await createMirror(newMirrorData)
                        if (!mirrorDataError) {
                            dispatchData({ type: "SET_MIRROR", payload: mirrorData });
                            checkUserVideoData(memorialData)
                            const imageUrl = memorialData.pictureUrl
                            const imageUrlArray = imageUrl.split('.')
                            const imageExt = imageUrlArray[imageUrlArray.length - 1]
                            const [imageFile, imageFileError, imageFileResponse] = await api.get(imageUrl, { responseType: 'blob', })
                            if (!imageFileError) {
                                const fileForUpload = new File([imageFile], `${mirrorData.mirrorId}.${imageExt}`, { type: imageFileResponse.headers['content-type'] });
                                console.log('PICTURE MEMORIAL', fileForUpload);
                                const formData = new FormData();
                                formData.append("image", fileForUpload);
                                const [, uploadImageError] = await setImage(mirrorData.mirrorId, formData);
                                if (uploadImageError) {
                                    alert('Error uploading mirror image!')
                                }
                            }

                        } else {
                            alert('mirrorDataError')
                        }
                    }
                }
            }
            dispatchData({ type: "SET_FIRST_LOAD", payload: true });
        })()
    }, [])



    useEffect(() => {//protect change data from Devtools
        const params = parseGetParamsWithB64()
        if (data?.mirror && data?.mirror?.mirrorId !== params?.memorial?.memorialId) {
            dispatchData({ type: "SET_MIRROR", payload: null });
        }
        console.log('data changed', data);
    }, [data])


    async function checkUserVideoData(memorialData) {
        const [userVideoData, userVideoDataError] = await getUserVideoData(memorialData.memorialId, memorialData.userId)
        console.log('userVideoData', userVideoData);
        if (!userVideoDataError) {
            dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
        }
        if (userVideoDataError && memorialData.userRole === 'maintainer') {
            const newUserVideoData = {
                relation: memorialData.userRelation,
                mirrorId: memorialData.memorialId,
                userId: memorialData.userId,
                userName: memorialData.userName,
            }
            const [userVideoData, userVideoDataError] = await setUserVideoData(newUserVideoData)
            if (!userVideoDataError) {
                dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
            } else {
                alert('userVideoDataError')
            }
        }
    }

    return <DataContext.Provider value={[data, dispatchData]}>{children}</DataContext.Provider>
}

export default DataProvider






