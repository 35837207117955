import React, { useState, useEffect } from "react";
import styles from "./RecordVideoSection.module.css";
import RecordVideo from "./../RecordVideo/RecordVideo";
import RecordProvider from "../../hooks/useRecord";
import { useRecord } from "./../../hooks/useRecord";
import { useData } from "./../../hooks/useData";
import { setVideo, getUserVideoData } from "../../api/crud";

export default function RecordVideoSection() {
  const [, dispatchData] = useData();

  useEffect(() => {
    dispatchData({ type: "INCREMENT_RENDER" });
  }, []);
  return (
    <RecordProvider>
      <Section></Section>
    </RecordProvider>
  );
}

function Section() {
  const [record, dispatch] = useRecord();
  const [data, dispatchData] = useData();
  const [disableUploadVideoBtn, setDisableUploadVideoBtn] = useState(false);
  const [uploadVideoBtnText, setUploadVideoBtnText] = useState("Upload video from device");

  useEffect(() => {
    record.show &&
      record.showButtons &&
      !record.error &&
      record.status &&
      record.status !== "acquiring_media" &&
      dispatch({ type: "SET_SHOW_BUTTONS", payload: false });

    if (record.error) {
      const error = record.error.replaceAll("_", " ").toUpperCase();
      alert(error);
    }
  }, [record]);

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setDisableUploadVideoBtn(true);
    setUploadVideoBtnText("Loading...");
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    const videoFile = new File([selectedFile], `${data.memorial.userId}${data.memorial.memorialId}.mp4`, {
      type: selectedFile.type,
    });
    formData.append("video", videoFile);
    const [uploadedVideo, uploadedVideoError] = await setVideo(
      data.memorial.memorialId,
      data.memorial.userId,
      formData
    );

    if (!uploadedVideoError) {
      if (uploadedVideo.duration > 180) {
        const videoDuration = (uploadedVideo.duration / 60).toFixed(1);
        alert(`Duration your video is ${videoDuration} minutes. Please, try another video or record with camera`);
        setUploadVideoBtnText("Upload Video");
        return;
      }
      const [userVideoData, userVideoDataError] = await getUserVideoData(
        data.memorial.memorialId,
        data.memorial.userId
      );
      
      if (!userVideoDataError) {
        dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
      }
      alert("Success upload video!");
    } else {
      alert("uploadedVideoError");
    }
    setDisableUploadVideoBtn(false);
    setUploadVideoBtnText("Upload video from device");
  };

  return (
    <>
      <section className="section">
        <div className="container">
          {/* <div>
            <p>status ({record.status})</p>
            <p>error ({record.error})</p>
          </div> */}
          <h2 className="section-title">Record your own video</h2>
          <p className="section-description">Please note! You can send only one video</p>

          <div className={styles["record-video-content"]}>
            {record.showButtons && (
              <div className={styles["choose-option"]}>
                <button
                  onClick={() => dispatch({ type: "SET_SHOW", payload: true })}
                  className={`${styles["choose-option__btn"]} main-btn`}
                >
                  Record video with camera
                </button>
                <span>or</span>
                <label className={`${styles["choose-option__btn"]} main-btn`}>
                  {uploadVideoBtnText}
                  <input
                    disabled={disableUploadVideoBtn}
                    onChange={onSelectFile}
                    id="file-upload"
                    type="file"
                    accept="video/*"
                  />
                </label>
              </div>
            )}
            {record.show && <RecordVideo></RecordVideo>}
          </div>
        </div>
      </section>
    </>
  );
}
