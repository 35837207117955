import React, { useState, useEffect } from "react";
import styles from "./SelectAudio.module.css";
import { useData } from "./../../hooks/useData";
import { getAudioList, setScenario } from "../../api/crud";
import { BASE_URL } from './../../api/crud';

export default function SelectAudio() {
  const [audios, setAudios] = useState([])
  const [selectAudio, setSelectAudio] = useState(null);
  const [data, dispatchData] = useData();

  const disabled = data.mirror.film && !data.editingFilm ? styles['disabled-selection'] : ''

  const emptyAudio = { id: 0, name: "Without background audio" };

  useEffect(() => {
    if (selectAudio !== null) {
      (async function () {
        const newScenario = {
          ...data.mirror.scenario,
          audioFile: selectAudio,
        };
        const equalsScenarios = JSON.stringify(data.mirror.scenario) === JSON.stringify(newScenario);
        if (!equalsScenarios) {
          const [, setScenarioError] = await setScenario(data.mirror.mirrorId, newScenario);
          if (!setScenarioError) {
            dispatchData({ type: "SET_SCENARIO", payload: newScenario });
          }
        }
      })();
    }
  }, [selectAudio]);

  useEffect(() => {
    setSelectAudio(data.mirror.scenario.audioFile);
  }, [data]);

  useEffect(() => {
    (async function () {
      const [audioList, audioListError ] = await getAudioList();
      if (!audioListError) {
        setAudios(audioList)
      }
    })()
  }, [])

  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Select audio</h2>
          <div className={disabled}>
            <form className={styles["form"]}>
              <div className="grid">
                {audios.map((audio) => (
                  <Audio
                    name={audio.Title}
                    src={audio.URL}
                    value={audio.id}
                    key={audio.id}
                    checked={selectAudio}
                    setter={setSelectAudio}
                  />
                ))}
              </div>
              <div className={styles["audio-controls"]}>
                <label className={styles["audio-controls__label"]}>
                  <input
                    className={styles["audio-controls__input"]}
                    onChange={() => setSelectAudio(emptyAudio.id)}
                    checked={selectAudio === emptyAudio.id}
                    type="radio"
                    name={emptyAudio.name}
                    value={emptyAudio.id}
                  />
                    {emptyAudio.name}
                </label>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

function Audio({ name, src, value, checked, setter }) {
  return (
    <div className={`${styles["grid-item"]} grid-item`}>
      <p className={styles["audio-name"]}>{name}</p>
      <audio className={styles["audio"]} src={src} controls></audio>

      <div className={styles["audio-controls"]}>
        <label className={styles["audio-controls__label"]}>
          <input className={styles["audio-controls__input"]} onChange={() => setter(value)} checked={checked === value} type="radio" name={name} value={value} />
          Select {name}
        </label>
      </div>
    </div>
  );
}
