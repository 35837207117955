import styles from './Header.module.css';
import logo from '../../assets/logo.png'

export default function Header() {
  return (
    <>
      <header className={styles['header']}>
        <div className="container">
          <div className={styles['header__content']}>
            <div className={styles['header__logo']}>
              <img className={styles['header__logo-img']} src={logo} alt="Logo" />
              <p className={styles['header__logo-text']} >KEEP YOUR LOVED ONE'S MEMORY ALIVE ONLINE</p>
            </div>

            <div  className={styles['header__right']}>
              <a className={`${styles['header__link']} `} href="https://beforever.online/" target="_blank" rel="noreferrer noopener">Main portal</a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}