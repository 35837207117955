import React from "react";
import './App.css';

import PersonInfo from './components/PersonInfo/PersonInfo'
import GuestInfo from './components/GuestInfo/GuestInfo'
import SelectVideos from './components/SelectVideos/SelectVideo'
import SelectAudio from './components/SelectAudio/SelectAudio'
import SelectPhoto from './components/SelectPhoto/SelectPhoto'
import RecordVideoSection from './components/RecordVideoSection/RecordVideoSection'
import PreviewFilmSection from './components/PreviewFilmSection/PreviewFilmSection'
import Footer from './components/Footer/Footer'
import PageNotFound from './components/PageNotFound/PageNotFound'
import Loading from './components/Loading/Loading'
import PreviewVideo from './components/PreviewVideo/PreviewVideo';
import AdditionalInfo from './components/AdditionalInfo/AdditionalInfo';
import { useData } from './hooks/useData';

// MAINTAINERS
// {
//   "memorialId": 111,
//   "userId": 111,
//   "userName": "Maintainer John",
//   "userRole": "maintainer",
//   "userRelation": "Friend",
//   "personName": "Mr. Smith",
//   "pictureUrl": "https://static.remove.bg/remove-bg-web/8be32deab801c5299982a503e82b025fee233bd0/assets/start-0e837dcc57769db2306d8d659f53555feb500b3c5d456879b9c843d1872e7baa.jpg",
//   "dateBirth": "2001-05-26",
//   "dateDeath": "2021-09-13"
// }
// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiAxMTEsCiAgInVzZXJOYW1lIjogIk1haW50YWluZXIgSm9obiIsCiAgInVzZXJSb2xlIjogIm1haW50YWluZXIiLAogICJ1c2VyUmVsYXRpb24iOiAiRnJpZW5kIiwKICAicGVyc29uTmFtZSI6ICJNci4gU21pdGgiLAogICJwaWN0dXJlVXJsIjogImh0dHBzOi8vc3RhdGljLnJlbW92ZS5iZy9yZW1vdmUtYmctd2ViLzhiZTMyZGVhYjgwMWM1Mjk5OTgyYTUwM2U4MmIwMjVmZWUyMzNiZDAvYXNzZXRzL3N0YXJ0LTBlODM3ZGNjNTc3NjlkYjIzMDZkOGQ2NTlmNTM1NTVmZWI1MDBiM2M1ZDQ1Njg3OWI5Yzg0M2QxODcyZTdiYWEuanBnIiwKICAiZGF0ZUJpcnRoIjogIjIwMDEtMDUtMjYiLAogICJkYXRlRGVhdGgiOiAiMjAyMS0wOS0xMyIKfQ


// {
//   "memorialId": 11,
//   "userId": 11,
//   "userName": "Maintainer Mark",
//   "userRole": "maintainer",
//   "userRelation": "Best Friend",
//   "personName": "John Dou",
//   "pictureUrl": "https://static.remove.bg/remove-bg-web/8be32deab801c5299982a503e82b025fee233bd0/assets/start-0e837dcc57769db2306d8d659f53555feb500b3c5d456879b9c843d1872e7baa.jpg",
//   "dateBirth": "2001-05-26",
//   "dateDeath": "2021-09-13"
// }
// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTEsCiAgInVzZXJJZCI6IDExLAogICJ1c2VyTmFtZSI6ICJNYWludGFpbmVyIE1hcmsiLAogICJ1c2VyUm9sZSI6ICJtYWludGFpbmVyIiwKICAidXNlclJlbGF0aW9uIjogIkJlc3QgRnJpZW5kIiwKICAicGVyc29uTmFtZSI6ICJKb2huIERvdSIsCiAgInBpY3R1cmVVcmwiOiAiaHR0cHM6Ly9zdGF0aWMucmVtb3ZlLmJnL3JlbW92ZS1iZy13ZWIvOGJlMzJkZWFiODAxYzUyOTk5ODJhNTAzZTgyYjAyNWZlZTIzM2JkMC9hc3NldHMvc3RhcnQtMGU4MzdkY2M1Nzc2OWRiMjMwNmQ4ZDY1OWY1MzU1NWZlYjUwMGIzYzVkNDU2ODc5YjljODQzZDE4NzJlN2JhYS5qcGciLAogICJkYXRlQmlydGgiOiAiMjAwMS0wNS0yNiIsCiAgImRhdGVEZWF0aCI6ICIyMDIxLTA5LTEzIgp9





//======================================================================================================================
// GUESTS
// {
//   "memorialId": 111,
//   "userId": 222,
//   "userName": "Guest David",
//   "userRole": "guest",
//   "userRelation": null,
//   "personName": "Mr. Smith",
//   "pictureUrl": "https://static.remove.bg/remove-bg-web/8be32deab801c5299982a503e82b025fee233bd0/assets/start-0e837dcc57769db2306d8d659f53555feb500b3c5d456879b9c843d1872e7baa.jpg",
//   "dateBirth": "2001-05-26",
//   "dateDeath": "2021-09-13"
// }
// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiAyMjIsCiAgInVzZXJOYW1lIjogIkd1ZXN0IERhdmlkIiwKICAidXNlclJvbGUiOiAiZ3Vlc3QiLAogICJ1c2VyUmVsYXRpb24iOiBudWxsLAogICJwZXJzb25OYW1lIjogIk1yLiBTbWl0aCIsCiAgInBpY3R1cmVVcmwiOiAiaHR0cHM6Ly9zdGF0aWMucmVtb3ZlLmJnL3JlbW92ZS1iZy13ZWIvOGJlMzJkZWFiODAxYzUyOTk5ODJhNTAzZTgyYjAyNWZlZTIzM2JkMC9hc3NldHMvc3RhcnQtMGU4MzdkY2M1Nzc2OWRiMjMwNmQ4ZDY1OWY1MzU1NWZlYjUwMGIzYzVkNDU2ODc5YjljODQzZDE4NzJlN2JhYS5qcGciLAogICJkYXRlQmlydGgiOiAiMjAwMS0wNS0yNiIsCiAgImRhdGVEZWF0aCI6ICIyMDIxLTA5LTEzIgp9

// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiAyMjMsCiAgInVzZXJOYW1lIjogIkd1ZXN0IERhdmlkIiwKICAidXNlclJvbGUiOiAiZ3Vlc3QiLAogICJ1c2VyUmVsYXRpb24iOiBudWxsLAogICJwZXJzb25OYW1lIjogIk1yLiBTbWl0aCIsCiAgInBpY3R1cmVVcmwiOiAiaHR0cHM6Ly9zdGF0aWMucmVtb3ZlLmJnL3JlbW92ZS1iZy13ZWIvOGJlMzJkZWFiODAxYzUyOTk5ODJhNTAzZTgyYjAyNWZlZTIzM2JkMC9hc3NldHMvc3RhcnQtMGU4MzdkY2M1Nzc2OWRiMjMwNmQ4ZDY1OWY1MzU1NWZlYjUwMGIzYzVkNDU2ODc5YjljODQzZDE4NzJlN2JhYS5qcGciLAogICJkYXRlQmlydGgiOiAiMjAwMS0wNS0yNiIsCiAgImRhdGVEZWF0aCI6ICIyMDIxLTA5LTEzIgp9

// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiAzMjIsCiAgInVzZXJOYW1lIjogIkd1ZXN0IE5pY2siLAogICJ1c2VyUm9sZSI6ICJndWVzdCIsCiAgInVzZXJSZWxhdGlvbiI6IG51bGwsCiAgInBlcnNvbk5hbWUiOiAiTXIuIFNtaXRoIiwKICAicGljdHVyZVVybCI6ICJodHRwczovL3N0YXRpYy5yZW1vdmUuYmcvcmVtb3ZlLWJnLXdlYi84YmUzMmRlYWI4MDFjNTI5OTk4MmE1MDNlODJiMDI1ZmVlMjMzYmQwL2Fzc2V0cy9zdGFydC0wZTgzN2RjYzU3NzY5ZGIyMzA2ZDhkNjU5ZjUzNTU1ZmViNTAwYjNjNWQ0NTY4NzliOWM4NDNkMTg3MmU3YmFhLmpwZyIsCiAgImRhdGVCaXJ0aCI6ICIyMDAxLTA1LTI2IiwKICAiZGF0ZURlYXRoIjogIjIwMjEtMDktMTMiCn0

// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiAxMiwKICAidXNlck5hbWUiOiAiR3Vlc3QgVmFkaW0iLAogICJ1c2VyUm9sZSI6ICJndWVzdCIsCiAgInVzZXJSZWxhdGlvbiI6IG51bGwsCiAgInBlcnNvbk5hbWUiOiAiTXIuIFNtaXRoIiwKICAicGljdHVyZVVybCI6ICJodHRwczovL3N0YXRpYy5yZW1vdmUuYmcvcmVtb3ZlLWJnLXdlYi84YmUzMmRlYWI4MDFjNTI5OTk4MmE1MDNlODJiMDI1ZmVlMjMzYmQwL2Fzc2V0cy9zdGFydC0wZTgzN2RjYzU3NzY5ZGIyMzA2ZDhkNjU5ZjUzNTU1ZmViNTAwYjNjNWQ0NTY4NzliOWM4NDNkMTg3MmU3YmFhLmpwZyIsCiAgImRhdGVCaXJ0aCI6ICIyMDAxLTA1LTI2IiwKICAiZGF0ZURlYXRoIjogIjIwMjEtMDktMTMiCn0



// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTExLAogICJ1c2VySWQiOiA3OTgsCiAgInVzZXJOYW1lIjogIkd1ZXN0IFZhbGVyYSIsCiAgInVzZXJSb2xlIjogImd1ZXN0IiwKICAidXNlclJlbGF0aW9uIjogbnVsbCwKICAicGVyc29uTmFtZSI6ICJNci4gU21pdGgiLAogICJwaWN0dXJlVXJsIjogImh0dHBzOi8vc3RhdGljLnJlbW92ZS5iZy9yZW1vdmUtYmctd2ViLzhiZTMyZGVhYjgwMWM1Mjk5OTgyYTUwM2U4MmIwMjVmZWUyMzNiZDAvYXNzZXRzL3N0YXJ0LTBlODM3ZGNjNTc3NjlkYjIzMDZkOGQ2NTlmNTM1NTVmZWI1MDBiM2M1ZDQ1Njg3OWI5Yzg0M2QxODcyZTdiYWEuanBnIiwKICAiZGF0ZUJpcnRoIjogIjIwMDEtMDUtMjYiLAogICJkYXRlRGVhdGgiOiAiMjAyMS0wOS0xMyIKfQ




//11


// http://localhost:3000/?memorial=ewogICJtZW1vcmlhbElkIjogMTEsCiAgInVzZXJJZCI6IDM0NTU1LAogICJ1c2VyTmFtZSI6ICJHdWVzdCBERCIsCiAgInVzZXJSb2xlIjogImd1ZXN0IiwKICAidXNlclJlbGF0aW9uIjogIiIsCiAgInBlcnNvbk5hbWUiOiAiSm9obiBEb3UiLAogICJwaWN0dXJlVXJsIjogImh0dHBzOi8vc3RhdGljLnJlbW92ZS5iZy9yZW1vdmUtYmctd2ViLzhiZTMyZGVhYjgwMWM1Mjk5OTgyYTUwM2U4MmIwMjVmZWUyMzNiZDAvYXNzZXRzL3N0YXJ0LTBlODM3ZGNjNTc3NjlkYjIzMDZkOGQ2NTlmNTM1NTVmZWI1MDBiM2M1ZDQ1Njg3OWI5Yzg0M2QxODcyZTdiYWEuanBnIiwKICAiZGF0ZUJpcnRoIjogIjIwMDEtMDUtMjYiLAogICJkYXRlRGVhdGgiOiAiMjAyMS0wOS0xMyIKfQ


function App() {
  const [data, dispatchData] = useData()

  return (
    <div className="App">
      {
        data.firstLoad ? <>
          {data.mirror ?
            <>
              {data.memorial.userRole === 'guest' &&
                <>
                  <PersonInfo />
                  {data.userVideoData ?
                    <>
                      {!data.userVideoData.uploadedVideo ? <RecordVideoSection /> :
                        <PreviewVideo />}
                    </>
                    : <GuestInfo />}

                  <Footer />
                </>}
              {data.memorial.userRole === 'maintainer' &&
                <>
                  <PersonInfo />
                  {data.userVideoData &&
                    <>
                      {!data.userVideoData.uploadedVideo ? <RecordVideoSection /> : <PreviewVideo />}
                      <SelectVideos />
                      <SelectAudio />
                      <AdditionalInfo />
                      <SelectPhoto />                  
                      <PreviewFilmSection />
                    </>
                  }
                  <Footer />
                </>}
            </> : <PageNotFound />
          }
        </> : <Loading />
      }
    </div>
  );
}

export default App;
