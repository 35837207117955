import React, { useState } from "react";
import { setUserVideoData } from "../../api/crud";
import styles from "./GuestInfo.module.css";
import { useData } from "./../../hooks/useData";

export default function GuestInfo() {
  const [relationText, setRelationText] = useState("");
  const [data, dispatchData] = useData();

  const handleChange = (e) => {
    setRelationText(e.target.value);
  };
  
  async function submitForm(e) {
    e.preventDefault();
    console.log(relationText);
    if (relationText.trim()) {
      const newUserVideoData = {
        relation: relationText.trim(),
        mirrorId: data.memorial.memorialId,
        userId: data.memorial.userId,
        userName: data.memorial.userName,
      };

      const [userVideoData, userVideoDataError] = await setUserVideoData(newUserVideoData);
      
      if (!userVideoDataError) {
        dispatchData({ type: "SET_USER_VIDEO_DATA", payload: userVideoData });
      } else {
        alert("userVideoDataError");
      }
    }
  }

  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Thank You</h2>
          <div className={styles["section-content"]}>
            <p className="section-description">
              We are very grateful to you for supporting this project with your video appeal. Just give us a bit of info
              about you. This will allow us to make the film more meaningful.
            </p>

            <form onSubmit={submitForm} className={styles["form"]}>
              <label className={styles["form-label"]} htmlFor="related">
                How are you related to the deceased?
              </label>
              <input
                onChange={handleChange}
                value={relationText}
                className={styles["form-input"]}
                id="related"
                name="relation"
                type="text"
                placeholder="For example best friend"
                required
              />
              <button className="main-btn" type="submit">
                Aplly
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
