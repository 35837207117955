import React, { useState, useEffect } from "react";
import styles from "./AdditionalInfo.module.css";
import { useData } from "./../../hooks/useData";
import { setScenario } from "../../api/crud";

export default function AdditionalInfo() {
  const [epitaph, setEpitaph] = useState("");
  const [dateBirth, setDateBirth] = useState(true);
  const [dateDeath, setDateDeath] = useState(true);
  const [filmQuote, setFilmQuote] = useState(false);
  const [data, dispatchData] = useData();

  const disabled = data.mirror.film && !data.editingFilm ? styles['disabled-selection'] : ''

  useEffect(() => {
    if (data.mirror) {
      setEpitaph(data.mirror.scenario.epitaph);
      setDateBirth(data.mirror.scenario.dateBirth);
      setDateDeath(data.mirror.scenario.dateDeath);
      setFilmQuote(!!data.mirror.scenario.epitaph);
    }
  }, [data]);

  useEffect(() => {
    if (!filmQuote) {
      setEpitaph("");
    }
  }, [filmQuote]);

  const submitForm = async (e) => {
    e.preventDefault();
    const newScenario = {...data.mirror.scenario, ...{
      dateBirth,
      dateDeath,
      epitaph,
    }};
    console.log(newScenario);
    const [, setScenarioError] = await setScenario(data.mirror.mirrorId, newScenario);
    if (!setScenarioError) {
      dispatchData({ type: "SET_SCENARIO", payload: newScenario });
    }
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <h2 className="section-title">Additional info</h2>
          <div className={disabled}>
            <form className={styles["form"]} onSubmit={submitForm}>
              <div className="grid">
                <div className={`${styles["grid-item"]} grid-item`}>
                  <label className={styles["form__label"]}>
                    <input
                      type="checkbox"
                      name="dateBirth"
                      onChange={() => setDateBirth((prevState) => !prevState)}
                      value={dateBirth}
                      checked={dateBirth}
                    />
                    Show date of birth in the film
                  </label>
                  <label className={styles["form__label"]}>
                    <input
                      type="checkbox"
                      name="dateBirth"
                      onChange={() => setDateDeath((prevState) => !prevState)}
                      value={dateDeath}
                      checked={dateDeath}
                    />
                    Show date of death in the film
                  </label>
                </div>

                <div className={`${styles["grid-item"]} grid-item`}>
                  <label className={styles["form__label"]}>
                    <input
                      type="checkbox"
                      name="filmQuote"
                      onChange={() => setFilmQuote((prevState) => !prevState)}
                      value={filmQuote}
                      checked={filmQuote}
                    />
                    Add introductory quote to the film
                  </label>
                  <textarea
                    required={filmQuote}
                    disabled={!filmQuote}
                    onChange={(e) => {
                      setEpitaph(e.target.value);
                    }}
                    value={epitaph}
                    className={styles["quote-text"]}
                    maxLength="150"
                    rows="6"
                    placeholder="Write a film quote"
                  ></textarea>
                </div>
                <button className={`${styles["submit-btn"]} main-btn`} type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
