import styles from './PersonInfo.module.css';
import Header from '../Header/Header'
import { useData } from './../../hooks/useData';

const formatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
})

export default function PersonInfo() {
  const [data] = useData()

  return (
    <>
      <section className={styles['section']}>
        <Header />
        <div className='container'>
          <div className={styles['person-info']}>
            <img className={styles['person-photo']} src={data.memorial.pictureUrl} alt='person-image'/>
            <div className={styles['person-details']} >
              <h1 className={styles['person-name']} >{data.mirror.personName}</h1>
              <h2 className={styles['person-date']} > {formatter.format(new Date(data.mirror.dateBirth))} - {formatter.format(new Date(data.mirror.dateDeath))}</h2>
            </div>
          </div>
        </div>         
      </section>
    </>
  );
}