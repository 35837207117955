import styles from './PageNotFound.module.css'

export default function PageNotFound() {
  
  return (
    <>
      <div className={styles["message"]}>
        <h1 className='section-title'>Something went wrong...</h1>
      </div>
    </>
  );
}