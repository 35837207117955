import axios from 'axios';
export const BASE_URL_SHORT = 'https://mirror-dev.beforever.online/'
export const BASE_URL = 'https://mirror-dev.beforever.online/api/'

export const api = axios.create({
    baseURL: BASE_URL
});

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('RAW response', response);
    if (typeof response.data === 'string' && response.data.length === 0) {
        return [true, null, response]
    }
    return [response.data, null, response];
}, function (error) {
    console.warn(`HTTP error! ${error}`);
    return [null, error, null]
});

export async function getMirror(mirrorId) {
    return await api.get(`mirror/${mirrorId}`)
}
export async function createMirror(mirrorData) {
    return await api.post(`mirror/create`, mirrorData, {headers: {'Content-type': 'application/json; charset=utf-8'}})
}
export async function setScenario(mirrorId, scenarioData) {
    return await api.post(`mirror/scenario/${mirrorId}`, scenarioData, {headers: {'Content-type': 'application/json; charset=utf-8'}})
}
export async function getUserVideoData(mirrorId, userId) {
    return await api.get(`uservideo/${mirrorId}/${userId}`)
}
export async function getVideoList(mirrorId) {
    return await api.get(`uservideo/list/${mirrorId}`)
}
export async function setUserVideoData(userVideoData) {
    return await api.post(`uservideo/update`, userVideoData, {headers: {'Content-type': 'application/json; charset=utf-8'}})
}
export async function setVideo(mirrorId, userId, formData) {
    return await api.post(`uservideo/upload/?mirrorId=${mirrorId}&userId=${userId}`, formData, {headers: {'Content-type': "multipart/form-data"}})
}
export async function setImage(mirrorId, formData) {
    return await api.post(`mirror/image/${mirrorId}`, formData, {headers: {'Content-type': "multipart/form-data"}})
}
export async function getAudioList() {
    return await api.get(`/audio/list`)
}
export async function setCompile(mirrorId) {
    return await api.get(`/mirror/compile/${mirrorId}`)
}
export async function getAudio(audioId) {
    return await api.get(`/audio/${audioId}`)
}